import farmer from './images/farmer.png';
import farmer2 from './images/farmer2.png';
import farmer3 from './images/farmer3.png';
import bg1 from './images/bg1.png';
import bg2 from './images/bg2.png';
import bg3 from './images/bg3.png';
import origin_verified from './images/origin_verified.png';
import origin_verified_black from './images/origin_verified_black.png';
import origin_verified_yellow from './images/origin_verified_yellow.png';
import raflatac_logo_large from './images/raflatac_logo_large.png';
import raflatac_bg from './images/raflatac_bg.png';

export default {
    originverified1: {
        headerColor: '#c0432c',
        topLogo: origin_verified,
        footerColor: 'white',
        showOrigin: true,
        showXeikon: true,
        showRaflatac: true,
        showXeikonLogo: true,
        sectionColors: [
            {background: '#c0432c', text: 'white'},
            {background: '#f4f4f4', text: '#102542'},
            {background: '#523c2c', text: 'white'},
        ],
        bg: bg1,
        locations: [
            {lat: 14.95377999999998, lng: -91.4596314},
            {lat: 14.481357320678251, lng: -90.63001287178056},
            {lat: 60.32761484771505, lng: 25.580752418544144},
        ],
        productInfo: {
            name: 'La Bendicion',
            availability: '15B 69kg',
            varietals: 'Catimore, Catuai'
        },
        farmInfo: {
            farmerName: 'Agapito Ajualip Gonzales',
            region: 'Cubulco',
            sizeAltitude: '1 HA / 1,700 MASL',
            process: `Naturally fermented,
            Sun dried,
            Fully washed`
        },
        cupProfile: 'Sweet, Fruity, Caramel Notes, Dark Chocolate, Molases, Intense Finish',
        backgroundStory: `Cubulco is located in Baja Verapaz department, a region with improving living conditions and one with the highest percentage of indigenous population (68%). The majority of producers are smallholders who own their wet mills and have traditional agricultural practices. Coffee plantations are located at altitudes ranging from 1,500 to 1,900 masl ( 4,900 – 5,900 fasl).
        Agapito Ajualip is a 2nd generation coffee farmer. He lives in the village of Chuachacalte, Cubulco, Baja Verapaz. His farm name, La Bendicion, is a literal translation to “The Blessing” claiming that this farm has been a true blessing in quality and consisitency since the beggining.
        The farm is located at the mountain top and it provides a great view of the village. Its surrounded by other farms and a forestry área as well, creating a good enviorment to grow coffee.
        
        His family consists of 6 members, his wife, his son and grandaughter and 2 grandchildren. They all help in other economic activities besides coffee, including cattle and fish farming.
        
        Agapito has invested in quality and good agronomical practices with the help of Volcafe Way and now will continue to harvest quality coffee beans.`,
        image: farmer,
        imageDescription: 'Agapito Ajualip outside his house where he dries his coffee in patios.'
    },

    originverified2: {
        headerClass: 'headerClipped',
        headerColor: '#c0432c',
        footerColor: 'rgba(0,0,0,0)',
        topLogo: origin_verified_black,
        showOrigin: true,
        showXeikon: true,
        showRaflatac: true,
        showXeikonLogo: true,
        sectionColors: [
            {background: '#c0432c', text: 'white'},
            {background: '#f4f4f4', text: '#102542'},
            {background: '#1d4248', text: 'white'},
        ],
        bg: bg2,
        locations: [
            {lat: 15.094115099999996, lng: -90.64099939999998},
            {lat: 14.481357320678251, lng: -90.63001287178056},
            {lat: 60.32761484771505, lng: 25.580752418544144},
        ],
        productInfo: {
            name: 'Las Piedras',
            availability: '15B 69kg',
            varietals: 'Catimore, Catuai, Sarchimor'
        },
        farmInfo: {
            farmerName: 'JOSE TELETOR CAMAJA',
            region: 'CUBULCO',
            sizeAltitude: '1.5 HA / 1,680 MASL',
            process: `Naturally fermented,
            Sun dried,
            Fully washed`
        },
        cupProfile: 'Well Balanced, Nice Acidity, Red Fruit, Intense Sweetness, Juicy, Brown Sugar',
        backgroundStory: `Cubulco is located in Baja Verapaz department, a region with improving living conditions and one with the highest percentage of indigenous population (68%). The majority of producers are smallholders who own their wet mills and have traditional agricultural practices. Coffee plantations are located at altitudes ranging from 1,500 to 1,900 masl ( 4,900 – 5,900 fasl).

        Don Jose Teletor is a 61 year old coffee farmer. He lives in the village of Xun, Cubulco, Baja Verapaz. His farm name comes from the abundance of rocks in his surroundings. Despite the challenge of farming in a rocky soil, Don Jose has managed to produce great quality coffee for many years. With the help of Volcafe Way, he has perfected some agronomical practices that have enhaced production and productivity.
        
        His farm has a forest friendly eco system and he takes care of the trees that provide his plants with shadow. Neighbor farms are also part of Volcafe Way.
        
        He lives with his wife and depend only on coffee. His 2 children are already grownups and have moved out of the house, starting life and career of their own.`,
        image: farmer2,
        imageDescription: 'Don Jose, in the middle of his farm at the begining of the crop, next to fully loaded coffee plants.'
    },

    originverified3: {
        headerColor: 'rgba(0,0,0,0)',
        footerColor: 'white',
        topLogo: origin_verified_yellow,
        showOrigin: true,
        showXeikon: true,
        showRaflatac: true,
        showXeikonLogo: true,
        sectionColors: [
            {background: '#656117', text: 'white'},
            {background: '#f4f4f4', text: '#102542'},
            {background: '#333b2c', text: 'white'},
        ],
        bg: bg3,
        locations: [
            {lat: 14.95377999999998, lng: -91.4596314},
            {lat: 14.481357320678251, lng: -90.63001287178056},
            {lat: 60.32761484771505, lng: 25.580752418544144},
        ],
        productInfo: {
            name: 'Las Naranjas',
            availability: '20B 69kg',
            varietals: 'Catimore, Catuai, Caturra, Sarchimor'
        },
        farmInfo: {
            farmerName: 'Agapito Ajualip Gonzales',
            region: '1 HA / 1,700 MASL',
            sizeAltitude: '2.5 HA / 1,650 MASL',
            process: `Naturally fermented,
            Sun dried,
            Fully washed`
        },
        cupProfile: 'Well Rounded Body, Sweet Acidity, Chocolate, Sugar Cane, Pears And Apple',
        backgroundStory: `Cubulco is located in Baja Verapaz department, a region with improving living conditions and one with the highest percentage of indigenous population (68%). The majority of producers are smallholders who own their wet mills and have traditional agricultural practices. Coffee plantations are located at altitudes ranging from 1,500 to 1,900 masl ( 4,900 – 5,900 fasl).
        
        Ruben Ajualip is 59 years old and related to Agapito Ajualip, another Volcafe Way member. His farm is located in the village of Chuachacalte, Cubulco, Baja Verapaz. Surrounded by citrics where the orange tree is predominant, he decided to name his farm after this. From his farm, you can observe other Volcafe Way farms, fish farms and nearby villages.
        
        Ruben lives outside his farm with his wife and 2 kids. His wife helps sustain the household with other economic activities such as livestock farming and grocery store.`,
        image: farmer3,
        imageDescription: `Ruben Ajualip in his farm. Next to a Ready to Harvest!! perfectly health 4 year old plant.
        Harvest 2018-2019`
    },
    raflatac: {
        headerColor: 'rgba(0,0,0,0)',
        topLogo: raflatac_logo_large,
        footerColor: 'white',
        sectionColors: [
            {background: '#1d4248', text: 'white'},
            {background: '#1d4248', text: 'white'},
            {background: '#1d4248', text: 'white'},
        ],
        bg: raflatac_bg,
        showOrigin: false,
        showXeikon: false,
        showRaflatac: true,
        showXeikonLogo: false
    },
}