import React from 'react';
import { withStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import KeyboardArrowUpRounded from '@material-ui/icons/KeyboardArrowUpRounded';
import ButtonBase from '@material-ui/core/ButtonBase';

import animateScrollTo from 'animated-scroll-to';

import LabelMaterialInfo from './LabelMaterialInfo';
import topLogo from './images/fossilFreeLogo.png';
import footerLogo from './images/footer_logo.png';
import headerLogo from './images/raflatac_logo_large.png';
import bg from './images/raflatac_bg.png';

class RafnxtApp extends React.Component {
  constructor() {
    super();
    this.state = {
    }
  }

  handleScrollButtonClick = () => {
    animateScrollTo(window.document.querySelector('#content'), {
      minDuration: 2000
    });
  }
  render() {
    const { classes } = this.props;

    return (
    <div className={classes.container}>
      <CssBaseline />
      <img className={classes.headerLogo} src={headerLogo}/>
      <img className={classes.topLogo} src={topLogo}/>
      <img className={classes.footerLogo} src={footerLogo}/>
      <img className={classes.bg} src={bg}/>
      <div className={classes.scrollButtonContainer}>
        <ButtonBase className={classes.scrollButton}>
          <KeyboardArrowUpRounded className={classes.scrollButtonIcon} onClick={this.handleScrollButtonClick} />
        </ButtonBase>
      </div>
      <div id="content" className={classes.content}>
        <LabelMaterialInfo/>
      </div>
    </div>);
  }
}

const styles = {
  container: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    objectFit: 'cover'
  },
  topLogoContainer: {
    position: 'relative'
  },
  topLogo: {
    position: 'absolute',
    top: '20%',
    right: 50,
    width: '60%',
    maxWidth: 400,
    objectFit: 'contain',
    zIndex: 2
  },
  footerLogo: {
    position: 'absolute',
    bottom: 100,
    right: 50,
    width: '60%',
    maxWidth: 400,
    objectFit: 'contain',
    zIndex: 2
  },
  headerLogo: {
    position: 'absolute',
    top: 30,
    left: 30,
    width: 100,
    objectFit: 'contain',
    zIndex: 2
  },
  content: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    minHeight: '100vh',
    padding: 30,
    backgroundColor: '#446610',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  scrollButton: {
    position: 'absolute',
    bottom: 20,
    backgroundColor: 'none',
    borderRadius: '50%',
    borderColor: 'white',
    borderWidth: 3,
    borderStyle: 'solid',
    transition: 'opacity 0.2s ease-in-out'
  },
  scrollButtonIcon: {
    width: 20,
    height: 20,
    color: 'white'
  },
  scrollButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  }
}

export default withStyles(styles)(RafnxtApp);