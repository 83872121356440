import React from 'react';

import OriginApp from './OriginApp';
import RafnxtApp from './RafnxtApp';

export default (props) => {
  const hostname = window.location.hostname;
  if(hostname.includes('rafnxt')) {
    return <RafnxtApp/>;
  } else {
    return <OriginApp/>;
  }
}