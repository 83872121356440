import React from 'react';
import { withStyles } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import KeyboardArrowUpRounded from '@material-ui/icons/KeyboardArrowUpRounded';
import ButtonBase from '@material-ui/core/ButtonBase';

import animateScrollTo from 'animated-scroll-to';
import classnames from 'classnames';

import topLogo from './images/origin_verified.png';
import topLogoBg from './images/bird.png';
import originIcon from './images/origin.png';
import raflatacLogo from './images/raflatac_logo.png';
import xeikonLogo from './images/xeikon_logo.png';
import magicAddLogo from './images/magic_add_logo.png';
import xeikonLogoRed from './images/xeikon_logo_red.png';
import bg1 from './images/bg1.png';

import Section from './Section';
import OriginInfo from './OriginInfo';
import originInfoConfig from './originInfoConfig';
import XeikonInfo from './XeikonInfo';
import LabelMaterialInfo from './LabelMaterialInfo';
import LabelMaterialInfo2 from './LabelMaterialInfo2';

class OriginApp extends React.Component {
  constructor() {
    super();
    this.state = {
    }
  }

  handleScrollButtonClick = () => {
    const config = this.getConfig()
    let selector;
    if (config.showOrigin) {
      selector = '#originSection';
    } else if (config.showXeikon) {
      selector = '#xeikonSection'
    } else {
      selector = '#materialSection'
    }

    animateScrollTo(window.document.querySelector(selector), {
      minDuration: 2000
    });
  }

  getConfig = () => {
    const hostname = window.location.hostname;
    if(hostname.includes('originverified3')) {
      return originInfoConfig.originverified3
    } else if(hostname.includes('originverified2')) {
      return originInfoConfig.originverified2
    } else {
      return originInfoConfig.originverified1
    }
  }

  render() {
    const { classes } = this.props;
    const { scrollY } = this.state;
    const config = this.getConfig()

    return (
    <div className={classes.container}>
      <CssBaseline />
      <div style={{backgroundColor: config.headerColor}} className={classnames([classes.header, classes[config.headerClass]])}/>
      <img className={classes.topLogo} src={config.topLogo}/>
      <img className={classes.bg} src={config.bg}/>
      <div style={{backgroundColor: config.footerColor}} className={classes.broughtToYouContainer}>
        <div className={classes.broughtToYouText}>Brought to you by:</div>
        <img className={classes.broughtToYouLogo} src={magicAddLogo}/>
        <img className={classes.broughtToYouLogo} src={raflatacLogo}/>
        {config.showXeikonLogo && <img className={classes.broughtToYouLogo} src={xeikonLogo}/>}
      </div>
      <div className={classes.scrollButtonContainer}>
        <ButtonBase style={{borderColor: config.sectionColors[0].background}} className={classes.scrollButton}>
          <KeyboardArrowUpRounded style={{color: config.sectionColors[0].background}} className={classes.scrollButtonIcon} onClick={this.handleScrollButtonClick} />
        </ButtonBase>
      </div>
      <div id="content" className={classes.content}>
        {config.showOrigin && <Section id="originSection" backgroundColor={config.sectionColors[0].background} textColor={config.sectionColors[0].text} title="Origin of the product" description="Track where your bag of coffee beans were sourced." icon={originIcon}>
          <OriginInfo config={config} textColor={config.sectionColors[0].text}/>
        </Section>}
        {config.showOrigin && <Section id="xeikonSection" backgroundColor={config.sectionColors[1].background} textColor={config.sectionColors[1].text} title="Xeikon integration" description="Add intelligence to your labels with our new digital printing integration." icon={xeikonLogoRed}>
          <XeikonInfo/>
        </Section>}
        {config.showRaflatac && <Section id="materialSection" backgroundColor={config.sectionColors[2].background} textColor={config.sectionColors[2].text} title="Label Material Origin" description="See which sustainably managed forest this RAFNXT+ label material came from." icon={originIcon}>
          <LabelMaterialInfo2/>
        </Section>}
      </div>
    </div>);
  }
}

const styles = {
  container: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  bg: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    objectFit: 'cover'
  },
  header: {
    backgroundColor: '#c0432c',
    height: 60,
    width: '100%',
    zIndex: 1
  },
  headerClipped: {
    height: 100,
    clipPath: 'polygon(0 0, 100% 0, 100% 26%, 0 100%)',
    '-webkit-clip-path': 'polygon(0 0, 100% 0, 100% 26%, 0 100%)',
  },
  topLogoContainer: {
    position: 'relative'
  },
  topLogo: {
    position: 'absolute',
    top: '20%',
    width: '60%',
    maxWidth: 400,
    objectFit: 'contain',
    zIndex: 2
  },
  topLogoBg: {
    height: 250,
    width: 250,
    marginTop: 150,
    objectFit: 'contain',
    zIndex: -1,
    transition: 'opacity 0.2s ease-in-out',
  },
  bottomContainer: {
    position: 'absolute',
    bottom: 20
  },
  content: {
    position: 'absolute',
    top: '100%',
    width: '100%',
    minHeight: '100vh'
  },
  scrollButton: {
    position: 'absolute',
    bottom: 20,
    backgroundColor: 'none',
    borderRadius: '50%',
    borderColor: '#c0432c',
    borderWidth: 3,
    borderStyle: 'solid',
    transition: 'opacity 0.2s ease-in-out'
  },
  scrollButtonIcon: {
    width: 20,
    height: 20,
    color: '#c0432c'
  },
  broughtToYouLogo: {
    margin: 5,
    marginRight: 10,
    marginLeft: 10,
    width: '25%',
    height: 20,
    objectFit: 'contain',
  },
  broughtToYouText: {
    paddingTop: 10,
    fontSize: 10,
    transition: 'opacity 0.2s ease-in-out',
  },
  broughtToYouContainer: {
    position: 'absolute',
    paddingBottom: 45,
    bottom: 0,
    width: '100%',
    backgroundColor: 'white'
  },
  scrollButtonContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  }
}

export default withStyles(styles)(OriginApp);