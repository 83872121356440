import React from 'react';
import { withStyles } from '@material-ui/styles';

import label1 from './images/label1.png';
import label2 from './images/label2.png';
import label3 from './images/label3.png';
import fossil_free_description from './images/fossil_free_description.png';
import rafnxt_1 from './images/rafnxt_1.jpeg';

import Map from './Map';


class LabelMaterialInfo extends React.Component {
  constructor() {
    super();
    this.state = {

    }
  }

  renderMap() {
    const { locations } = this.state;

    return <Map
        googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyApXL_amNeE46hYHT-bryVcyj0sxEQyOBA&v=3.exp&libraries=geometry,drawing,places"
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `400px` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        locations={locations}
        drawPolyline={false}
    >
    </Map>
  }

  render() {
    const { classes } = this.props;

    return (<div className={classes.container}>
        <div className={classes.text}>
        UPM Raflatac has joined forces with other UPM businesses to innovate for a future beyond fossils.
        As a concrete result of this collaboration, UPM Raflatac brings wood-based renewable UPM Raflatac Fossil-Free Adhesive into the market.
        </div>
        <img className={classes.image} src={label3}/>
        <img className={classes.image} src={fossil_free_description}/>
        <img className={classes.image} src={label2}/>
        <div className={classes.text}>
        <div className={classes.title}>WE COMBINE FOSSIL-FREE ADHESIVE WITH RAFNXT+ LABEL MATERIAL</div>
        The source forests for RAFNXT+ labels are 100% FSC certified and absorb more carbon than is emitted in making the product. These sustainably managed forests are positive for nature; there is zero deforestation, biodiversity is promoted, water is protected and climate change adaptation is enhanced. RAFNXT+ material also enables a superior carbon performance compared to standard labels through the smarter use of natural resources. 
        </div>
        <img className={classes.image} src={rafnxt_1}/>
    </div>);
  }
}

const styles = {
  container: {
      textAlign: 'left',
      maxWidth: 600
  },
  image: {
    maxHeight: 600,
    width: '100%',
    objectFit: 'contain',
    marginTop: 10,
    marginBottom: 10
  },
  text: {

  },
  title: {
    fontSize: 20,
    fontWeight: 'bold'
  }
}

export default withStyles(styles)(LabelMaterialInfo);