import React from 'react';
import { withScriptjs, withGoogleMap, GoogleMap, Marker, Polyline } from "react-google-maps"

import marker from './images/marker.png'
import googleMapStyles from './googleMapStyles.json'

import _ from 'lodash'

class MyMapComponent extends React.Component {

    constructor() {
        super();

        this.state = {
            
        }
    }

    componentDidMount() {
        this.getCurrentPosition();
    }

    renderMarkers() {
        const { currentPosition } = this.state;
        let { locations } = this.props;

        if(currentPosition) {
            locations = locations.concat([currentPosition])
        }
        return _.flatMap(locations)
            .map(location =>
                <Marker position={location} icon={{ url: location.icon || marker, anchor: location.anchor || {x: 5, y: 5} }}/>
            )
    }

    renderPolylines() {
        const { currentPosition } = this.state;
        let { locations } = this.props;

        if(currentPosition) {
            locations = locations.concat([currentPosition])
        }

        const polylines = [];
        const polylineOptions = {
            strokeColor: "#c0432c",
            strokeOpacity: 0.8,
            strokeWeight: 2,
            geodesic: true
        }

        locations.forEach((location, index) => {
            if (index + 1 >= locations.length) {
                return
            }
            const next = locations[index+1];

            if (Array.isArray(location)) {
                polylines.push(location.map(subLocation =>
                    <Polyline path={[subLocation, next]} options={polylineOptions}/>)
                );
            } else {
                polylines.push(<Polyline path={[location, next]} options={polylineOptions}/>)
            }
        })

        return polylines;
    }

    getCurrentPosition = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log(position.coords);
                    this.setState(prevState => ({
                        currentPosition: {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                    }))
                }
            )
        }
    }

    updateMapBounds = (map) => {
        const { locations } = this.props;

        const bounds = new window.google.maps.LatLngBounds();
        _.flatMap(locations).forEach(location => bounds.extend(location));
        map.fitBounds(bounds);
      };

    handleMapReady = (map) => {
        this.updateMapBounds(map);
    }

    render() {
        const { drawPolyline } = this.props;
        return <GoogleMap
            ref={this.handleMapReady}
            defaultZoom={8}
            defaultCenter={{ lat: -34.397, lng: 150.644 }}
            options={{styles: googleMapStyles, mapTypeControl: false, streetViewControl: false}}
        >
        {this.renderMarkers()}
        {drawPolyline && this.renderPolylines()}
      </GoogleMap>
    }
}

export default withScriptjs(withGoogleMap(MyMapComponent))