import React from 'react';
import { withStyles } from '@material-ui/styles';

import xeikon1 from './images/xeikon1.png';
import xeikon2 from './images/xeikon2.png';

class XeikonInfo extends React.Component {
  constructor() {
    super();
    this.state = {
    }
  }


  render() {
    const { classes } = this.props;

    return (<div className={classes.container}>
        <div>
          UPM Raflatac's intelligent label solution, RafMore by UPM Raflatac, is now available faster and more securely than ever thanks to a groundbreaking integration between Xeikon's digital printing presses and Magic Add's Internet of Packaging platform. It is now possible to simply choose to add unique IDs to labels during the normal printing process.<br/><br/>
          Magic Add’s IoP platform, which powers UPM Raflatac’s RafMore smart labels, can now send unique IDs to Xeikon digital printers directly from the cloud. Xeikon can automatically and securely print RafMore smart labels with unique digital identities in the form of any standard, machine-readable code with shorter turnarounds and in greater quantities than ever possible before.
        </div>

        <img className={classes.image} src={xeikon1}/>
        <img className={classes.image} src={xeikon2}/>
        <div>
          “Integrating Magic Add’s unique ID generation into Xeikon’s X-800 work flow enables new applications and reduces the time because there’s no need for manual integration,” says Jeroen Van Bauwel, Director of Product Management at Xeikon.<br/><br/>
          Using smart labels to give products unique digital identities brings a variety of benefits without adding much to label unit cost. UPM Raflatac’s RafMore smart labels can be used to track product origin, authenticate products and fight counterfeiting, make product recalls as narrow as possible, show consumers sustainability credentials, and more. In return for offering users more product information and supply chain transparency, brands gain valuable market insight data based on real-time usage.
        </div>
    </div>);
  }
}

const styles = {
    container: {
        textAlign: 'left',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 600
    },
    image: {
        maxHeight: 400,
        width: '100%',
        objectFit: 'contain',
        marginTop: 20,
        marginBottom: 20
    }
}

export default withStyles(styles)(XeikonInfo);