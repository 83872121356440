import React from 'react';
import { withStyles } from '@material-ui/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Collapse from '@material-ui/core/Collapse';
import animateScrollTo from 'animated-scroll-to';

class Section extends React.Component {
  constructor() {
    super();
    this.state = {
      collapse: false
    }
  }

  handleReadMore = () => {
    setTimeout(()=> {
      animateScrollTo(document.querySelector(`#${this.props.id}`), {
        speed: 1000
      });
    }, 200);

    this.setState({
      collapse: !this.state.collapse
    })
  }

  render() {
    const { classes, title, icon, description, children, backgroundColor, textColor, id } = this.props;
    const { collapse } = this.state;
    return (<div id={id} style={{ backgroundColor: backgroundColor, color: textColor }} className={classes.container}>
        <img className={classes.icon} src={icon}/>
        <div className={classes.title}>{title}</div>
        <div className={classes.description}>{description}</div>
        <Collapse style={{ color: textColor }} className={classes.collapse} in={collapse}>{children}</Collapse>
        <ButtonBase style={{ borderColor: textColor, color: textColor }} className={classes.readMoreButton} onClick={this.handleReadMore}>{collapse ? 'Hide' : 'Read more'}</ButtonBase>
    </div>);
  }
}

const styles = (theme) => ({
    container: {
        backgroundColor: 'gray',
        minHeight: 300,
        padding: 40
    },
    icon: {
        width: 100,
        height: 40,
        objectFit: 'contain',
        margin: 10
    },
    title: {
      fontSize: 20,
      textTransform: 'uppercase',
      fontWeight: 'bold'
    },
    description: {
      marginBottom: 20,
    },
    readMoreButton: {
        backgroundColor: 'none',
        borderColor: 'gray',
        color: 'gray',
        borderWidth: 3,
        borderStyle: 'solid',
        padding: '5px 20px 5px 20px',
        margin: 20
      },
      collapse: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
      }
});

export default withStyles(styles)(Section);